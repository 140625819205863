module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://headless.redrockswebdevelopment.com/graphql","schema":{"typePrefix":"Wp","queryDepth":15,"circularQueryLimit":5,"timeout":30000,"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"production":{"allow404Images":true,"allow401Images":true,"hardCacheMediaFiles":false},"html":{"useGatsbyImage":true,"generateWebpImages":true,"imageMaxWidth":800,"imageQuality":90,"createStaticFiles":true,"placeholderType":"blurred","fallbackImageMaxWidth":1024,"generateAvifImages":false},"type":{"Post":{"limit":5000},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/branding/red-rocks-web-dev-logo-minimal.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"be7cbd127c627cf9ccdf250df285b64e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
